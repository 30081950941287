<template>
  <section class="layout h-screen flex items-center">
    <div class="container">
      <div class="text-center text-3xl">
        <div v-if="show">Verifying your email... Please wait</div>
        <div v-else>Email verified. You can now login</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  mounted() {
    this.$store.dispatch(window.store.GET_USER_VERIFY, {
      expires: this.$route.query.expires,
      id: this.$route.query.id,
      signature: this.$route.query.signature,
    }).then(() => {
      this.show = false;
    });
  },
};
</script>
